body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  text-align: center;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

header, footer {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  text-align: center;
}

header h1, footer p {
  margin: 0;
}
