.construction-message {
  background: linear-gradient(to bottom, #ffffff, #90C9EB, #69AED5);
  background-image: url('../../images/background.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  text-align: center;
  position: relative;
}

.message {
  margin: auto;
  width: 600px;
  position: absolute;
  top: 40%;
  left: 70%;
  transform: translate(-50%, -50%);
  display: row;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}

.message h1 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
}

.message p {
  font-size: 17px;
  color: #666;
  margin-bottom: 20px;
}

.paragraphs {
  text-align: start;
  padding: 30px 40px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.learn-more {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.learn-more:hover {
  background-color: #555;
}

.img {
  position: absolute;
}


@media (max-width: 1000px) {
  .message {
    width: 55%;
    min-width: 300px;
    left: 69%;
    top: 45%;
  }

}


@media (max-width: 500px) {

  .construction-message {
    background: linear-gradient(to bottom, #ffffff, #90C9EB, #69AED5);
    background-image: url('../../images/digipaw-webresponsive.png');
    background-size: cover;
    display: flex;
  }

  .message {
    position: absolute;
    top: 45%;
    left: 50%;
    display: row;
    max-width: 90%;
  }

}